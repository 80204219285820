<template>

  <head>
    <link href="https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap" rel="stylesheet" />
    <link href="//db.onlinewebfonts.com/c/9f94dc20bb2a09c15241d3a880b7ad01?family=Menlo" rel="stylesheet"
      type="text/css" />
  </head>
  <!-- register section -->
  <div style="position: relative;">
    <section id="register" class="introDescription">
      <HeaderNews />
      <div class="homeimg">
        <div class="col-md-5">
          <p class="h1 fw-bold ps-3" id="innro" style="
            font-size: 35px;
          ">Empowering you for
            the future of
          </p>
          <p class="h1 fw-bold ps-3" id="innro" style="
            font-size: 35px;
          ">
            Technology Development
          </p>
          <p id="innro" style="
            margin: 15px 5px 0px 20px;
            font-family: 'Nunito' sans-serif;
            line-height: 2;
            font-weight: 400;
            font-size: 17px;
          ">Start your Technology based career and attain proficiency in 3months with access to real projects &
            internship opportunities
          </p>
          <br>
          <div class="ps-3">
            <!-- <router-link class="btn" role="button" id="bttn" :to="{
            name: 'register',
            params: { type: 'tsch', courseoption: 'Fullstack Website Development + Data Analysis With Python' },
          }">Technology Schools</router-link> -->
            <a type="button" id="bttn" class="btn btn-outline" href="#schools">Technology Schools</a>
            <a type="button" class="btn btn-outline ms-1" href="https://wa.me/2349031844175?text=Hello, TechOrigin, I will like to take the skill test" target="_blank">Take Skill Test</a>
          </div>
        </div>
        <div class="intropic">
          <img src="../assets/ttds_fullstack_2025.jpg" id="techorigin-home" alt="TTDS24" />
        </div>
        <i class="moving-design fa fa-laptop" style="bottom:50px;right:100px"></i>
        <i class="moving-design fa fa-laptop" style="top:20%;left:60%"></i>
        <i class="moving-design fa fa-code" style="bottom:100px;right:90px"></i>
        <i class="moving-design fa fa-desktop" style="bottom:200px;right:0px"></i>
        <i class="moving-design fa fa-database" style="top:0px;left:0px"></i>
        <i class="moving-design fa fa-code" style="top:30%;right:50%"></i>
        <i class="moving-design fa fa-database" style="top:50%;left:50%"></i>
        <i class="moving-design fa fa-code" style="top:0px;right:400px"></i>
        <i class="moving-design fa fa-mobile" style="bottom:50px;right:400px"></i>
        <i class="moving-design fa fa-object-group" style="bottom:0px;right:0px"></i>
        <i class="moving-design fa fa-mobile" style="bottom:20%;left:40%"></i>
        <i class="moving-design fa fa-object-group" style="bottom:20%;right:40%"></i>
      </div>
    </section>
    <div class="row" data-aos="zoom-in" data-aos-duration="2500" data-aos-delay="500" data-aos-once="true" id="about">
      <center>
        <div class="col-md-9 justify-content-center" style="margin-bottom: 30px">

          <h3 class="primary-color" style="
                font-size: 30px;
                font-family: 'Trebuchet MS', 'Lucida Sans Unicode',
                  'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
                margin: 40px 0px 10px 0px;
              ">
            Why TechOrigin?
          </h3>
          <div style="
            justify-content: center;
            width: 80px;
            background-color: rgb(34, 116, 143);
            padding: 2px;
            margin: 0px 0px 30px 0px;
          "></div>
          <p style="
            margin: 0px 5% 10px 5%;
            font-family: 'Nunito' sans-serif;
            line-height: 1.9;
            font-size: 18px;
          ">
            TechOrigin is more than just a learning center – we're your partner in acquiring relevant technology skills.
            Enroll in our Technology School to enhance your career prospects by gaining practical and hands-on
            knowledge.
            <br>
            <br>
            We empower you with the requisite information needed not only to excel in professional endeavors but also to
            spark innovative ideas for entrepreneurs in the dynamic world of technology development.
          </p>
          <br />
          <p style="
            margin: 0px 5% 10px 5%;
            font-family: 'Nunito' sans-serif;
            line-height: 1.9;
            font-size: 18px;
          ">
            Our mission is to accelerate the development of sustainable technology in Africa, particularly Nigeria,
            enabling us to address local technological needs, create more jobs, and advance our technological landscape
          </p>
        </div>
      </center>
      <div v-if="false"
        style="display:flex; flex-flow:row wrap;justify-content:center;margin-top:40px; row-gap:30px; column-gap:30px;">
        <div>
          <div
            style="width:150px;height:150px;color:white;font-size:50px;background-color:rgb(8, 103, 136);border-radius:50%;justify-content:center;align-items:center;display:flex">
            80+
          </div>
          <p style="font-size:18px;font-weight:bold;margin-top:10px">Certified Students</p>
        </div>
        <div>
          <div
            style="width:150px;height:150px;color:white;font-size:50px;background-color:rgb(8, 103, 136);border-radius:50%;justify-content:center;align-items:center;display:flex">
            4
          </div>
          <p style="font-size:18px;font-weight:bold;margin-top:10px">Countries Reached</p>
        </div>
        <div>
          <div
            style="width:150px;height:150px;color:white;font-size:50px;background-color:rgb(8, 103, 136);border-radius:50%;justify-content:center;align-items:center;display:flex">
            13+
          </div>
          <p style="font-size:18px;font-weight:bold;margin-top:10px">Technology Trainings</p>
        </div>
        <div>
          <div
            style="width:150px;height:150px;color:white;font-size:50px;background-color:rgb(8, 103, 136);border-radius:50%;justify-content:center;align-items:center;display:flex">
            20+
          </div>
          <p style="font-size:18px;font-weight:bold;margin-top:10px">Projects Supervised</p>
        </div>
      </div>

    </div>
    <section data-aos="zoom-in" data-aos-duration="2500" data-aos-delay="500" data-aos-once="true" id="program">
      <div class="row" data-aos="zoom-in" data-aos-duration="2500" data-aos-delay="500" data-aos-once="true">
        <CurrentPrograms></CurrentPrograms>
      </div>
    </section>
    <section data-aos="zoom-in" data-aos-duration="2500" data-aos-delay="500" data-aos-once="true"
      style="margin-bottom:0px 5px 30px 5px;" id="schools">
      <schools></schools>
    </section>
    <section id="learning" class="introDescription" data-aos="zoom-in" data-aos-duration="2500" data-aos-delay="500"
      data-aos-once="true">
      <LearningJourney></LearningJourney>
    </section>

    <section data-aos="zoom-in" data-aos-duration="2500" data-aos-delay="500" data-aos-once="true" id="courses"
      style="background-color: #f3f7fa; padding: 50px 0px 0px 0px">
      <Courses></Courses>
    </section>

    <div class='messenger'>
      <a target='_blank' rel='noreferrer'
        href="https://wa.me/2349031844175?text=Hello, TechOrigin, I will like to enquire about Technology School!">
        <div>
          <span class="need-help">Need Help? <b>Chat with us</b></span>
          <img style="width: 50px;margin-bottom: 5px;" :src="require('../assets/whatsapp.png')" alt="whatsapp" />
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import HeaderNews from './HeaderNews.vue';
import schools from './schools.vue';
import CurrentPrograms from './CurrentPrograms.vue';
import LearningJourney from './LearningJourney.vue';
import Courses from './Courses.vue';
export default {
  name: "Int_ro",
  components: {
    HeaderNews,
    schools,
    CurrentPrograms,
    LearningJourney,
    Courses
  },
  methods: {
    showAlert() {
      alert('Coming soon')
    }
  }
};
</script>

<style scoped>
.need-help {
  font-size: 13px;
  padding: 0px 0px;
  border-radius: 3px;
  border: none
}

.messenger {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #F8F9FA;
  color: #0d1b25;
  /* width: 80px; */
  padding: 0px 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0px;
  cursor: pointer;
}

.messenger a {
  margin-top: 10px;
  color: rgb(8, 103, 136);
  text-decoration: none;
}

.primary-color {
  color: rgb(8, 103, 136);
}

.primary-background-color {
  color: rgb(8, 103, 136);
}

#par {
  color: rgb(8, 103, 136);
  font-family: Nunito, sans-serif;
  line-height: 27.2px;
}

.flex-style-prog {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  column-gap: 10px;
  row-gap: 10px;
}

.p-text {
  font-family: "Nunito" sans-serif;
  line-height: 1.9;
  font-size: 18px;
}

.row {
  justify-content: space-around;
}

.course-container {
  background-color: #f4f7f9;
  padding: 20px 30px 40px 30px;
}

#innro {
  color: rgb(8, 103, 136);
}

#packs {
  border-radius: 6px;
  overflow: hidden;
  background-color: white;
  box-shadow: 4px 3px 8px 1px #d4d3d3;
  -webkit-box-shadow: 4px 3px 8px 1px #d4d3d3;
  transition: 0.7s;
}

#eff {
  transform: scale(1.07);
}

#eff:hover {
  transition: 1.02s;
  transform: scale(1.09);
}

#register {
  background-color: #f4f7f9;
}

.altBackgroundColor {
  background-color: #f4f7f9;
}

#bttn {
  background-color: rgb(8, 103, 136);
  color: white;
  transition: 0.5s;
}

.homeimg {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0px;
  position: relative;
}

.intropic {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 20px;
  padding: 10px 30px;
}

.header-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  text-align: start;
}

.btn-outline {
  color: rgb(8, 103, 136);
  border: 1.8px solid rgb(8, 103, 136);
  transition: 0.6s all ease;
}

.btn-outline:hover {
  background: rgb(8, 103, 136);
  color: white;
  z-index: -1;
  transition: all 0.6s ease;
}

#bttn:hover {
  background-color: rgb(34, 116, 143);
}

#packs:hover {
  box-shadow: 4px 3px 8px 1px #969696;
  -webkit-box-shadow: 4px 3px 8px 1px #969696;
  cursor: pointer;
}

.moving-design {
  position: absolute;
  font-size: 30px;
  opacity: 0.1;
  color: rgb(34, 116, 143);
}

.page-image-design {
  max-width: 30%;
}

.page-content-design {
  max-width: 65%;
  margin-left: 10px;
}

#techorigin-home {
  border-radius: 20px;
  width: 450px;
}

.whatsapp {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 80px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  #register {
    width: 100%;
  }

  .page-content-design {
    max-width: 80%;
    text-align: center;
  }

  .flex-style-prog {
    justify-content: center;
  }

  .intropic {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 0px;
    margin-top: 20px;
    padding: 0px;
  }

  #techorigin-home {
    border-radius: 20px;
    width: 380px;
  }



}
</style>
