<template lang="en-us">
    <head>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    </head>
    <div class="background">
        <div class="review-tab">
            <div style="display:flex;flex-direction:column;justify-content:center;align-items:center">
                <img  style="width:70px;"  src='../assets/ALISU_logo.png'/>
                <p style="margin:0px 0px 5px 0px;text-align:center;font-weight:bold;font-size:18px;color:rgb(8, 103, 136)">TechOrigin Technology Development School</p>
                <p style="margin:0px;text-align:center" 
                v-if="certIsValid!='valid'">Please enter your certificate Id to verify your certificate availability</p>
            </div>

      <div class="bg-light first my-3 name" style="overflow:hidden" v-if="certIsValid!='valid'">
        <label class="h6 ps-3 py-2"
          >Certificate Id <span style="color: red">*</span></label
        ><br />
        <input
          class="inputs ms-3"
          title="Certificate Id"
          type="text"
          id="name"
          v-model="certId"
          name="name"
          style="width:100%"
          placeholder="TTDS/CH03/0011 or TTDS:CH03:0011"
          required
        />
        <div class="ms-3 mb-3 cover"><div class="effect"></div></div>
        <p v-if="certIsValid=='invalid'" class="inputs ms-3" style="font-weight:bold;color:red">Invalid Certificate Id</p>
      </div>
      <button
       v-if="certIsValid!='valid'"
        type="submit"
        value="Submit"
        id="submit"
        class="submit-button"
        @click="verifyCertificate">Submit</button>
        <div class="ms-5 mt-4" v-if="certIsValid=='valid'">
            <p>Your certificate is verified... &nbsp;&nbsp;<i class="fas fa-check-circle" style="color:green"></i></p>
            <p @click="openVerification()" style="cursor:pointer;color:rgb(8, 103, 136);text-decoration:underline">Click on the link to download certificate</p>
        </div>
        </div>

    </div>
</template>
<script>
import axios from "axios";
import store from "../shared/store";
import { LOADING_SPINNER_SHOW_MUTATION } from "../shared/storeconstants";
export default {
  name: "ReviewTab",
  data() {
    return {
      rating: 0,
      fullname: "",
      comment: "",
      success: false,
      certId: "",
      certNumber: "",
      certIsValid: "",
    };
  },
  methods: {
    setRating(i) {
      this.rating = i;
    },
    openVerification() {
      if (this.certId.length > 5) {
        window.open(
          `https://firebasestorage.googleapis.com/v0/b/techorigin-web.appspot.com/o/Certificates%2F${this.certNumber}.png?alt=media&token=2146117d-d4c7-4796-a02b-9e345cf71a12`
        );
      }
    },
    async verifyCertificate() {
      store.commit(LOADING_SPINNER_SHOW_MUTATION, true);
      try {
        const link_data = "https://script.google.com/macros/s/AKfycbw1908dwakW48mh-XfxEXcpk4GoLbCC_1bV8N56TzlBE8139FiHnwCVU_lpXgD7w126/exec?path=verifyCert&id=" +
          this.certId
        const res = await axios.get(link_data);
        if (res.data.includes("TTDS")) {
          this.certNumber = res.data;
          this.certIsValid = "valid"
        }
        else {
          this.certIsValid = "invalid"
        }
      } catch (error) {
        console.log(error);
        this.certIsValid = "invalid";
      }
      store.commit(LOADING_SPINNER_SHOW_MUTATION, false);
    },
  },
};
</script>
<style>
.review-rating {
  color: rgb(8, 103, 136);
  font-size: 30px;
  margin-right: 5px;
  cursor: pointer;
}

.background {
  background-color: rgb(8, 103, 136);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.review-tab {
  width: 50%;
  background-color: white;
  border: white solid 2px;
  box-shadow: 1px 1px 1px white;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
  padding: 10px;
  row-gap: 10px;
}

.submit-button {
  background-color: rgb(8, 103, 136);
  color: white;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  border: none;
}

.first {
  box-shadow: 4px 3px 8px 1px #d4d3d3;
  -webkit-box-shadow: 4px 3px 8px 1px #d4d3d3;
  border: 1px solid rgb(182, 177, 177);
  margin-top: -22px;
  border-radius: 4px;
}

.inputs {
  border: none;
  outline: 0px solid transparent;
  background-color: rgb(248, 249, 250);
}

.text-area-input {
  min-height: 70px;
  width: 95%;
}

.effect {
  background-color: rgb(8, 103, 136);
  height: 1px;
  width: 0px;
  transition: width 0.8s;
}

.effect {
  transition-timing-function: linear;
}

.name:hover .effect {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .review-tab {
    width: 100%;
  }

  .text-area-input {
    width: 90%;
  }
}
</style>
